/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 127번째 로그에서는 GeekNews Show, 당근 SRE Meetup, Amazon MemoryDB for Redis에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "GeekNews Show"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=4828"
  }, "GeekNews Show 공개 - GeekNews")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/ask"
  }, "GeekNews Ask - Geek 들의 질문과 답변 - GeekNews")), "\n"), "\n", React.createElement(_components.h3, null, "Amazon MemoryDB for Redis"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/aws/introducing-amazon-memorydb-for-redis-a-redis-compatible-durable-in-memory-database-service/"
  }, "Introducing Amazon MemoryDB for Redis - AWS News Blog")), "\n"), "\n", React.createElement(_components.h3, null, "당근 SRE Meetup"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/1737"
  }, "당근 SRE 밋업 1회 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=4Q8It_UvIws&list=PLaHcMRg2hoBqWWla-pCBSRqU-jSriiZHj"
  }, "당근 SRE 밋업 1회 발표 영상 플레이리스트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/daangn/SRE-1-ca64e762e18e492fa449f8e43f0114d5"
  }, "당근 SRE 밋업 1회 발표자료")), "\n"), "\n", React.createElement(_components.h3, null, "시골 유학"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jne.go.kr/jne/main.do"
  }, "전남농산어촌유학")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
